import type { SiteConfig } from '@/_types'
// import { gotoAlias } from '@/goto-alias'

export const siteConfig: SiteConfig = {
  name: 'holtwick',
  host: 'holtwick.de',

  languages: ['en', 'de'],
  i18nLocalesModules: {
    en: () => import('../locales/en.json'),
    de: () => import('../locales/de.json'),
  },

  siteTitle: 'Dirk Holtwick',
  baseUrl: 'https://holtwick.de',
  iconUrl: 'https://holtwick.de/logo.svg',

  posts: () => import('./_posts/meta.json'),
  postsPath: 'blog',

  // plausibleScriptUrl: '/js/script.js',
  plausibleId: 'holtwick.de',

  repliesFaq: true,
  repliesProductName: 'Holtwick',
  repliesClientId: '97p290opikg4pm3e1kun2v5lvs',

  supportEmail: 'support@holtwick.de',

  // Activate social media sharing
  shareIntro: 'Found on holtwick.de',

  // sentryDsn: 'https://7432ec71e3ed19eb66211b3ff0263bff@o120938.ingest.us.sentry.io/4507435545591808',
  // sentryDsn: 'https://b3af122532d3499b9f9472c4e1655d05@bugs.int.holtwick.de/3',

  // gotoAlias,

}
