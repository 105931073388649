<script setup lang="ts">
import { useMagicKeys, whenever } from '@vueuse/core'
import { Search } from 'lucide-vue-next'
import { defineAsyncComponent, ref } from 'vue'

import './app-search.styl'

defineProps<{
  title: string
}>()

const show = ref(false)

function doShow() {
  show.value = !show.value
}

const { Ctrl_K, Cmd_K } = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.key === 'k' && (e.ctrlKey || e.metaKey) && e.type === 'keydown')
      e.preventDefault()
  },
})

whenever(Ctrl_K, doShow)
whenever(Cmd_K, doShow)

const AppSearchContent = defineAsyncComponent(() => import('./app-search-content.vue'))
</script>

<template>
  <button class="app-header-menu" :aria-label="title" :tooltip="title" @click="show = !show">
    <Search :alt="title" />
  </button>

  <ClientOnly>
    <teleport to="body">
      <AppSearchContent v-model="show" />
    </teleport>
  </ClientOnly>
</template>
