<script setup lang="ts">
import type { OuiMenuItem } from 'oui-kit'
import { useDark, useToggle, useWindowScroll } from '@vueuse/core'
import { LayoutGrid, Moon, Sun } from 'lucide-vue-next'
import { OuiFloat } from 'oui-kit'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import logoBriefing from '../assets/app/briefing.png?url'
import logoPDFify from '../assets/app/pdfify.png?url'
import logoR1 from '../assets/app/receipts.png?url'
import logoX2i from '../assets/app/xml2invoice.png?url'
import svgLogo from '../assets/ho.svg'
import messages from './app-header.locale.json'
import AppSearch from './app-search.vue'

import './app-header.styl'
import 'oui-kit/css'

withDefaults(defineProps<{
  title?: string
  logo?: string
  logo2x?: string
  apps?: boolean | undefined
  search?: boolean | undefined
  help?: boolean
  theme?: boolean
  language?: boolean
  rounded?: boolean
}>(), {
  apps: true,
  search: true,
  theme: true,
  language: true, // globalThis?.siteConfig?.languages?.length > 1,
})

const route = useRoute()
const router = useRouter()

const { locale, t } = useI18n({ messages })

const isDark = useDark()
const toggleDark = useToggle(isDark)

const {
  name,
  postsPath,
} = globalThis.siteConfig

const otherLangRoute = computed(() => {
  const newPrefix = `/${locale.value === 'de' ? 'en' : 'de'}`
  let newPath = `${newPrefix}${(route.meta.canonical as string ?? route.path).slice(3)}`

  // fixes trailing slash; depends on: ssgOptions: {dirStyle: "nested"}
  // if (!newPath.endsWith("/")) newPath = newPath + '/'
  if (newPath.endsWith('/'))
    newPath = newPath.slice(0, -1)

  // log('newPath', newPath)

  if (router.getRoutes().map(r => r.path).includes(newPath))
    return newPath

  return newPrefix
})

const appsElement = ref()

type ProductMenuItem = OuiMenuItem & {
  tooltip: string
  icon: string
}

const items = computed<ProductMenuItem[]>(() => {
  const info: ProductMenuItem[] = [
    {
      title: 'Receipts',
      tooltip: 'Receipts',
      url: 'https://receipts-app.com',
      close: true,
      disabled: name === 'receipts',
      icon: logoR1,
    },
    {
      title: 'XML2Invoice',
      tooltip: 'XML2Invoice',
      url: 'https://about.xml2invoice.com',
      close: true,
      disabled: name === 'xml2invoice',
      icon: logoX2i,
    },
    {
      title: 'PDFify',
      tooltip: 'PDFify',
      url: 'https://pdfify.app',
      close: true,
      disabled: name === 'pdfify',
      icon: logoPDFify,
    },
    {
      title: 'Briefing',
      tooltip: 'Briefing',
      url: 'https://about.brie.fi',
      close: true,
      disabled: name === 'briefing',
      icon: logoBriefing,
    },
  ]

  return info
})

// const showAppsMenu = ref(false)

const { y } = useWindowScroll()
</script>

<template>
  <header class="app-header" :class="{ _scrolled: y !== 0 }">
    <nav class="app-header-inner">
      <div class="app-header-title" :class="{ 'app-header-title-rounded': rounded }">
        <router-link :to="`/${$i18n.locale}`" aria-label="Homepage">
          <slot name="logo">
            <img v-if="logo" width="48" height="48" :src="logo" :srcset="logo2x ? `${logo2x} 2x` : undefined" alt="Logo">
            <svgLogo v-else alt="Logo" width="48" height="48" />
          </slot>
          <div class="app-header-title-text">
            <slot name="title">
              {{ title ?? '' }}
            </slot>
          </div>
        </router-link>
      </div>

      <div class="app-header-nav">
        <slot>
          <slot name="menu">
            <router-link class="app-header-menu" :to="`/${locale}/${postsPath}`">
              {{ t("posts.title") }}
            </router-link>

            <router-link class="app-header-menu" :to="`/${locale}/projects`">
              {{ t("projects.title") }}
            </router-link>
          </slot>
        </slot>
      </div>

      <div class="app-header-helpers">
        <ClientOnly>
          <!-- <button v-if="apps" ref="appsElement" class="app-header-menu" aria-label="Other Projects">
            <LayoutGrid alt="Other Projects" />
          </button>

          <OuiMenu
            v-if="apps"
            v-model="showAppsMenu"
            hover
            :reference="appsElement"
            :items="items"
          /> -->

          <template v-if="apps">
            <OuiFloat
              placement="bottom"
              :offset="10"
              close
              hover
              arrow
              class="oui-float _dropdown"
              transition="oui-float-transition"
            >
              <template #trigger>
                <button ref="appsElement" class="app-header-menu" aria-label="Other Projects">
                  <LayoutGrid alt="Other Projects" />
                </button>
              </template>

              <div class="app-header-apps">
                <div class="_intro">
                  {{ t("header.appsIntro") }}
                </div>
                <template v-for="item in items" :key="item.url">
                  <div v-if="item.disabled" class="_item _active">
                    <img :src="item.icon" loading="lazy" alt="Icon" height="24" width="24">
                    <span>{{ item.title }}</span>
                  </div>
                  <a v-else :href="item.url" target="_blank" class="_item">
                    <img :src="item.icon" loading="lazy" alt="Icon" height="24" width="24">
                    <span>{{ item.title }}</span>
                  </a>
                </template>
              </div>
            </OuiFloat>
          </template>

          <AppSearch v-if="search" :title="t('header.toggleSearch')" />

          <button v-if="theme" class="app-header-menu" aria-label="Switch Dark Mode" :tooltip="t('header.toggleDarkMode')" @click="toggleDark()">
            <Sun v-if="isDark" alt="Light Mode" />
            <Moon v-else alt="Dark Mode" />
          </button>
        </ClientOnly>

        <router-link v-if="language" class="app-header-menu app-header-menu-locale" :to="otherLangRoute" aria-label="Change Language" :tooltip="t('header.switchLanguage')" :data-lang="locale" :data-path="route.path">
          <slot name="language">
            <!-- <Languages /> -->
            {{ locale === 'de' ? 'EN' : 'DE' }}
          </slot>
        </router-link>
      </div>
    </nav>
  </header>
</template>
