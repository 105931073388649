import { useHead } from '@unhead/vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { arrayMinus, isNotEmpty } from 'zeed'

function buildTitle(title: string) {
  // return title
  return `${title.trim()} | ${siteConfig.siteTitle}`
}

export function useAppHeadHoltwick() {
  const route = useRoute()
  const router = useRouter()

  const { t } = useI18n()

  const {
    languages = ['en'],
    iconUrl,
    baseUrl,
    siteTitle,
    postsPath,
  } = globalThis.siteConfig

  const headInfo = computed(() => {
    const canonical = (route?.meta?.canonical ?? '') as string

    let lang = canonical.slice(1, 3)
    if (!languages.includes(lang))
      lang = 'en'

    const noLangPath = canonical.slice(3)

    // console.log(route.fullPath, lang)
    const post = (route?.meta?.post ?? {}) as any

    const title = buildTitle(post?.title ?? siteConfig.siteTitle ?? t('site.title'))
    const description = post?.data?.desc ?? post?.data?.description ?? t('site.description')

    // A general recommendation today is to keep your page description between 110 and 160 characters, although Google can sometimes show longer snippets.
    // if (description.length < 110 || description.length > 160)
    //   console.warn(`Description length is ${description.length} for ${route.fullPath}`)

    // x-default is preferable `en`, but if missing it is the current language
    let xdefault = lang
    const alternates = languages.map((lang: string) => {
      if (router.getRoutes().some(r => r.path === `/${lang}${noLangPath}`)) {
        if (lang === 'en')
          xdefault = lang
        return {
          rel: 'alternate',
          hreflang: lang,
          href: `${baseUrl}/${lang}${noLangPath}`,
        }
      }
      return undefined
    }).filter(isNotEmpty)

    alternates.push({ rel: 'alternate', hreflang: 'x-default', href: `${baseUrl}/${xdefault}${noLangPath}` })

    const info: any = {
      title,
      htmlAttrs: {
        lang,
        // 'data-priority': post.priority,
      },
      meta: [
        { name: 'description', content: description },

        { name: 'generator', content: 'Proprietary development based on Vite SSG, see https://holtwick.de/blog/website-ssg' },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:creator', content: '@holtwick' },
        { name: 'twitter:site', content: '@holtwick' },
        { name: 'twitter:image', content: iconUrl },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description },

        // https://ogp.me/
        // title and description are generated automatically
        // Check via https://metatags.io/?url=https%3A%2F%2Fholtwick.de
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: iconUrl },
        { property: 'og:url', content: baseUrl + canonical },
        { property: 'og:locale', content: lang },
        { property: 'og:locale:alternate', content: arrayMinus(languages, [lang]) },
        { property: 'og:site_name', content: siteTitle },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
      ],
      link: [
        { rel: 'canonical', href: baseUrl + canonical },
        ...alternates,
      ],
      templateParams: {
        site: {
          name: siteTitle,
          url: baseUrl,
        },
        separator: '|',
      },
      // titleTemplate: '%s %separator %site.name',

      // Quick darkmode
      script: [
        { textContent: `
(function () {
  const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  const setting = localStorage.getItem('vueuse-color-scheme') || 'auto'
  if (setting === 'dark' || (prefersDark && setting !== 'light'))
    document.documentElement.classList.toggle('dark', true)
})()
` },
      ],
    }

    if (postsPath) {
      info.link.push(
        // RSS Feeds
        { rel: 'alternate', type: 'application/rss+xml', title: siteTitle, href: '/feed.xml' },
        { rel: 'alternate', type: 'application/rss+xml', title: `${siteTitle} (de)`, href: '/de/feed.xml' },
      )
    }

    const { plausibleId } = globalThis.siteConfig

    if (plausibleId) {
      info.script.push({
        'src': globalThis.plausibleScriptUrl ?? 'https://data.holtwick.de/js/plausible.js',
        'defer': 'defer',
        'data-domain': plausibleId,
      })
    }

    return info
  })

  useHead(headInfo as any)
}

export function useHeadTitle(titleString: string) {
  const title = buildTitle(titleString)
  useHead({
    title,
    meta: [
      { name: 'twitter:title', content: title },
      { property: 'og:title', content: title },
    ],
  })
}

export function useHeadDescription(description: string) {
  // if (description == null) {
  //   const { t } = useI18n()
  //   description = t('site.description')
  // }
  useHead({
    meta: [
      { name: 'description', content: description },
      { name: 'twitter:description', content: description },
      { property: 'og:description', content: description },
    ],
  })
}
